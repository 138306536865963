import React from "react"
import { Link } from "gatsby"
import { Flex, Box, Button as RebassButton } from "rebass"
import Spinner from "../spinner"

const Button = ({
  loading,
  children,
  variant,
  type,
  onClick,
  disabled,
  sx,
  innerSx,
  to,
  ...props
}) => {
  const handleClick = e => {
    if (!loading && onClick) {
      onClick(e)
    }
  }

  const button = (
    <RebassButton
      sx={{
        position: "relative",
        cursor: loading ? "default" : "pointer",
        ...(sx || {}),
      }}
      disabled={disabled}
      loading={loading}
      onClick={handleClick}
      type={type || "button"}
      variant={`buttons.${variant}`}
      {...props}
    >
      {loading && (
        <Flex
          alignItems={"center"}
          justifyContent="center"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        >
          <Spinner height={"65%"} dark={variant === "cta" ? false : true} />
        </Flex>
      )}
      <Box
        sx={{
          visibility: loading ? "hidden" : "visible",
          ...innerSx,
        }}
      >
        {children}
      </Box>
    </RebassButton>
  )

  if (!!to) {
    return <Link to={to}>{button}</Link>
  }

  return button
}

export default Button
