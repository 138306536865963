import { Link, navigate } from "gatsby"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { Flex, Text } from "rebass"
import Button from "../../components/button"
import InputField from "../../components/input"
import LoginLayout from "../../components/login-layout"
import Spinner from "../../components/spinner"
import { useAuth } from "../../context/auth"

const IndexPage = () => {
  const [loading, setLoading] = useState(false)
  const auth = useAuth()
  const { register, handleSubmit } = useForm()

  const handleLogin = data => {
    setLoading(true)
    auth
      .handleLogin("email", data)
      .then(() => {
        navigate("/a")
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <LoginLayout pt={3} title={"Email Login"}>
      <Text
        onClick={() => navigate(`/login${location.search}`)}
        mt={-2}
        sx={{
          cursor: "pointer",
          fontSize: 0,
          color: "link",
          fontWeight: 500,
          ":hover": {
            color: "medusa",
          },
        }}
      >
        Back
      </Text>
      <Text mb={4} fontWeight="bold" fontSize={4}>
        Sign in with Email
      </Text>
      {loading ? (
        <Flex justifyContent="center">
          <Spinner dark width="20px" height="20px" />
        </Flex>
      ) : (
        <form onSubmit={handleSubmit(handleLogin)}>
          <InputField
            mb={3}
            label="Email"
            name="email"
            ref={register}
            boldLabel={true}
          />
          <InputField
            type="password"
            label="Password"
            boldLabel={true}
            name="password"
            ref={register}
          />
          <Button type="submit" variant={"cta"} mt={4} width={1}>
            Continue
          </Button>
        </form>
      )}
      <Text fontSize={0}>
        Don't have an account? <Link to="/signup">Sign up</Link>
      </Text>
    </LoginLayout>
  )
}

export default IndexPage
